import * as React from 'react'
import { useTranslation } from 'react-i18next'
import * as z from 'zod'
import { HtmlMouseButtonEventHandler } from '@digital-magic/react-common'
import { hasValue, reorderArray } from '@digital-magic/ts-common-utils'
import {
  FacadeColor,
  LayoutTypeId,
  LayoutTypeImages as LayoutTypeImagesModel,
  useCreateLayoutTypeImage,
  useDeleteLayoutTypeImage,
  useSetLayoutTypeLandingPrimaryImage,
  useSetLayoutTypePrimaryImage,
  useUpdateLayoutTypeImagesOrder
} from '@api/endpoints/buildings/layoutTypes'
import { getSmallThumbnailId, useFileUpload, useFilesDownload } from '@api/endpoints/files'
import { useAdminDefaultErrorHandler } from '@hooks/useAdminDefaultErrorHandler'
import { Check, PlaylistAddCheck } from '@mui/icons-material'
import { Box } from '@mui/material'
import { DropResult } from '@hello-pangea/dnd'
import { Button } from '@controls/Button'
import { DraggableImageList } from '@controls/DraggableImageList'
import { Form, FormFileInput, useFormTyped } from '@controls/Form'
import { useLayoutTypeFormContext } from '../LayoutTypeFormContext'
import { LayoutTypeImagesStyled } from './LayoutTypeImages.styles'

const LayoutTypeImagesFormValues = z.object({
  images: z.instanceof(FileList)
})
type LayoutTypeImagesFormValues = z.infer<typeof LayoutTypeImagesFormValues>

type Props = {
  facadeColor: FacadeColor
}

export const LayoutTypeImages: React.FC<Props> = ({ facadeColor }) => {
  const { t } = useTranslation()
  const { layoutType } = useLayoutTypeFormContext()
  const defaultErrorHandler = useAdminDefaultErrorHandler()

  const images: LayoutTypeImagesModel = React.useMemo(
    () => layoutType.images.filter((v) => v.facadeColor === facadeColor).sort((a, b) => a.orderNumber - b.orderNumber),
    [layoutType.images, facadeColor]
  )

  const layoutTypeId: LayoutTypeId = layoutType.id

  const uploadFile = useFileUpload({ onError: defaultErrorHandler })
  const createImage = useCreateLayoutTypeImage({ onError: defaultErrorHandler })
  const deleteImage = useDeleteLayoutTypeImage({ onError: defaultErrorHandler })
  const setLayoutTypeLandingPrimaryImage = useSetLayoutTypeLandingPrimaryImage({ onError: defaultErrorHandler })
  const setLayoutTypePrimaryImage = useSetLayoutTypePrimaryImage({ onError: defaultErrorHandler })
  const updateImagesOrder = useUpdateLayoutTypeImagesOrder({ onError: defaultErrorHandler })
  const downloadFiles = useFilesDownload(images.map(getSmallThumbnailId) ?? [], { onError: defaultErrorHandler })

  const isLoading =
    uploadFile.isLoading ||
    createImage.isLoading ||
    deleteImage.isLoading ||
    setLayoutTypeLandingPrimaryImage.isLoading ||
    setLayoutTypePrimaryImage.isLoading ||
    updateImagesOrder.isLoading ||
    downloadFiles.isFetching

  const onSubmit = async ({ images }: LayoutTypeImagesFormValues): Promise<void> => {
    // eslint-disable-next-line functional/no-loop-statements
    for (const image of images) {
      const uploadResp = await uploadFile.mutateAsync(image)
      createImage.mutate({
        layoutTypeId,
        fileId: uploadResp.id,
        facadeColor,
        primaryImage: undefined,
        primaryLandingImage: undefined
      })
    }
  }

  const onDeleteImage =
    (index: number) =>
    (confirmResult: boolean): void => {
      const fileId = images[index].id

      if (fileId && confirmResult) {
        deleteImage.mutate({ layoutTypeId, fileId })
      }
    }

  const onDragEnd = (result: DropResult): void => {
    if (
      hasValue(result.destination) &&
      hasValue(result.source.index) &&
      result.destination.index !== result.source.index
    ) {
      const reordered = reorderArray(images, result.source.index, result.destination.index).map((v, i) => ({
        ...v,
        orderNumber: i
      }))
      updateImagesOrder.mutate({
        layoutTypeId: layoutType.id,
        facadeColor: facadeColor,
        items: reordered.map((v) => ({
          item: v.id,
          index: v.orderNumber
        }))
      })
    }
  }

  const onSetLayoutTypeLandingPrimaryImage: (index: number) => HtmlMouseButtonEventHandler = (index) => (e) => {
    e.preventDefault()
    const fileId = images[index].id
    setLayoutTypeLandingPrimaryImage.mutate({ layoutTypeId: layoutType.id, fileId })
  }

  const onSetLayoutTypePrimaryImage: (index: number) => HtmlMouseButtonEventHandler = (index) => (e) => {
    e.preventDefault()
    const fileId = images[index].id
    setLayoutTypePrimaryImage.mutate({ layoutTypeId: layoutType.id, fileId, facadeColor })
  }

  const form = useFormTyped({
    resolver: LayoutTypeImagesFormValues,
    onSubmit
  })

  const imageUrls = React.useMemo(
    (): ReadonlyArray<string> => downloadFiles.data?.map((f) => URL.createObjectURL(f)) ?? [],
    [downloadFiles.data]
  )

  const isFormEditDisabled = false
  //useMemo(() => !isEditableEntity(layoutType.allowedActions), [layoutType])

  return (
    <LayoutTypeImagesStyled>
      <Box display="flex" flexDirection="column" rowGap={2}>
        <Form f={form}>
          <DraggableImageList
            title={t('pages.admin.layout_type.images.title') + ': ' + t(`enums.facade_color.${facadeColor}`)}
            imageUrls={imageUrls}
            disabled={isLoading || isFormEditDisabled}
            onDragEnd={onDragEnd}
            onDeleteImage={onDeleteImage}
            additionalControls={(i) => [
              images[i].primaryLandingImage ? (
                <Button key={`primaryLandingImageButton-${i}`} disabled color="grey600">
                  <PlaylistAddCheck />
                </Button>
              ) : (
                <Button
                  key={`primaryLandingImageButton-${i}`}
                  disabled={isLoading}
                  onClick={onSetLayoutTypeLandingPrimaryImage(i)}
                  title="Set primary landing image"
                >
                  <PlaylistAddCheck />
                </Button>
              ),
              images[i].primaryImage ? (
                <Button key={`primaryImageButton-${i}`} disabled color="grey600">
                  <Check />
                </Button>
              ) : (
                <Button
                  key={`primaryImageButton-${i}`}
                  disabled={isLoading}
                  onClick={onSetLayoutTypePrimaryImage(i)}
                  title="Set primary image for facade color"
                >
                  <Check />
                </Button>
              )
            ]}
          />
          <Box mt={2} mb={2} borderTop={1} color="#E6E6E6" />
          <Box display="flex" alignItems="center" columnGap={2}>
            <span>{t('pages.admin.layout_type.images.upload.title')}:</span>
            <FormFileInput
              name={form.names.images}
              disabled={isLoading || isFormEditDisabled}
              accept="image/*"
              multiple
            />
          </Box>
          <Box mt={2}>
            <Button type="submit" disabled={isLoading || isFormEditDisabled}>
              {t('pages.admin.layout_type.images.buttons.add')}
            </Button>
          </Box>
        </Form>
      </Box>
    </LayoutTypeImagesStyled>
  )
}
